import {LineItemModel} from './LineItem.model';

export class PaymentPolicyModel {
  public subtitle?: string;
  public policy?: string;

  constructor(lineItem?: LineItemModel) {
    this.subtitle = lineItem?.productName ?? /* istanbul ignore next */ undefined;
    this.policy = lineItem?.consentRequiredPaymentPolicy ?? /* istanbul ignore next */ undefined;
  }
}
